import React, { useState, useEffect } from 'react';
//import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  Box,
  AppBar,
  Toolbar,
} from '@mui/material';
import { styled } from '@mui/system';
import axiosInstance from './axiosConfig';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const Utilization = () => {
  const [utilizations, setUtilizations] = useState([]);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState('');

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const monthsToDisplay = [
    monthNames[currentMonthIndex],
    monthNames[(currentMonthIndex + 1) % 12],
    monthNames[(currentMonthIndex + 2) % 12]
  ];

  useEffect(() => {
    loadUtilizations();
    loadUsers();
    loadProjects();
  }, []);

  const loadUtilizations = async () => {
    try {
      const response = await axiosInstance.get('/utilizations/');
      setUtilizations(response.data.filter(utilization => utilization.year === currentYear));
    } catch (error) {
      console.error('Error fetching utilizations:', error);
      setError('Error fetching utilizations');
    }
  };

  const loadUsers = async () => {
    try {
      const response = await axiosInstance.get('/users/');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Error fetching users');
    }
  };

  const loadProjects = async () => {
    try {
      const response = await axiosInstance.get('/projects/');
      setProjects(response.data);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError('Error fetching projects');
    }
  };

  const getUserName = (userId) => {
    const user = users.find((u) => u.id === userId);
    return user ? user.username : 'Unknown';
  };

  const getProjectName = (projectId) => {
    const project = projects.find((p) => p.id === projectId);
    return project ? `${project.company} ${project.name}` : 'Unknown';
  };

  const getColor = (total) => {
    if (total < 0.7) {
      return '#ffcccc'; // Light Red
    } else if (total > 1) {
      return '#99ccff'; // Light Blue
    } else if (total === 1) {
      return '#ccffcc'; // Light Green
    } else {
      return '#ffcc99'; // Light Orange
    }
  };

  const groupedUtilizations = utilizations.reduce((acc, utilization) => {
    const { user_id, month, project_id, utilization: util } = utilization;
    if (!acc[user_id]) {
      acc[user_id] = { months: {} };
    }
    if (!acc[user_id].months[month]) {
      acc[user_id].months[month] = [];
    }
    acc[user_id].months[month].push({ project_id, util });
    return acc;
  }, {});

  return (
    <Container sx={{ paddingTop: '20px' }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Utilization Overview</Typography>
        </Toolbar>
      </AppBar>
      <Box mt={4}>
        <Typography variant="h4" sx={{ marginBottom: '20px' }}>
          Utilizations for {currentYear}
        </Typography>
        {error && (
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        )}
        <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableHeaderCell>Employee Name</StyledTableHeaderCell>
                {monthsToDisplay.map(month => (
                  <StyledTableHeaderCell key={month}>{month}</StyledTableHeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(user => (
                <TableRow key={user.id}>
                  <StyledTableCell>{user.username}</StyledTableCell>
                  {monthsToDisplay.map(month => {
                    const monthUtilizations = groupedUtilizations[user.id] && groupedUtilizations[user.id].months[month] 
                      ? groupedUtilizations[user.id].months[month].filter(({ util }) => util > 0) 
                      : [];
                    const totalUtilization = monthUtilizations.reduce((sum, { util }) => sum + util, 0);

                    return (
                      <StyledTableCell key={month} sx={{ backgroundColor: getColor(totalUtilization) }}>
                        {monthUtilizations.length > 0 ? (
                          <>
                            {monthUtilizations.map(({ project_id, util }, index) => (
                              <div key={index}>
                                {getProjectName(project_id)}: {util.toFixed(2)}
                              </div>
                            ))}
                            <div style={{ fontWeight: 'bold', marginTop: '10px' }}>
                              Total: {totalUtilization.toFixed(2)}
                            </div>
                          </>
                        ) : (
                          'N/A'
                        )}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default Utilization;
