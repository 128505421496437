import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { Button, Container, Typography, Box } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

const Login = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch(e => console.error(e));
  };

  useEffect(() => {
    console.log("Login: Rendering login page");
  }, []);

  if (isAuthenticated && accounts.length > 0) {
    console.log("Login: User is authenticated, redirecting to landing page");
    return <Navigate to="/" />;
  }

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Welcome to the App
        </Typography>
        <Button variant="contained" color="primary" onClick={handleLogin}>
          Login with Azure AD
        </Button>
      </Box>
    </Container>
  );
};

export default Login;
