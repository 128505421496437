import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  Container,
  Box,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';
import { styled } from '@mui/system';
import axiosInstance from './axiosConfig';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const Employees = () => {
  const [employees, setEmployees] = useState([]);
  const [newEmployees, setNewEmployees] = useState([{ username: '', email: '', position: '' }]);
  const [error, setError] = useState('');
  const [editEmployeeId, setEditEmployeeId] = useState(null);
  const [editEmployee, setEditEmployee] = useState({ username: '', email: '', position: '' });

  useEffect(() => {
    loadEmployees();
  }, []);

  const loadEmployees = async () => {
    try {
      const response = await axiosInstance.get('/users/');
      const sortedEmployees = response.data.sort((a, b) => getFirstName(a.username).localeCompare(getFirstName(b.username)));
      setEmployees(sortedEmployees);
    } catch (error) {
      console.error('Error fetching employees:', error);
      setError('Error fetching employees');
    }
  };

  const getFirstName = (username) => {
    const parts = username.split(' ');
    return parts[0];
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const employeesCopy = [...newEmployees];
    employeesCopy[index] = { ...employeesCopy[index], [name]: value };
    setNewEmployees(employeesCopy);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditEmployee((prevEditEmployee) => ({ ...prevEditEmployee, [name]: value }));
  };

  const handleAddEmployeeField = () => {
    setNewEmployees([...newEmployees, { username: '', email: '', position: '' }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/users/', newEmployees);
      const sortedEmployees = [...employees, ...response.data].sort((a, b) => getFirstName(a.username).localeCompare(getFirstName(b.username)));
      setEmployees(sortedEmployees);
      setNewEmployees([{ username: '', email: '', position: '' }]);
    } catch (error) {
      console.error('Error adding employees:', error);
      setError('Error adding employees');
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.put(`/users/${editEmployeeId}`, editEmployee);
      const sortedEmployees = employees.map((employee) => (employee.id === editEmployeeId ? response.data : employee)).sort((a, b) => getFirstName(a.username).localeCompare(getFirstName(b.username)));
      setEmployees(sortedEmployees);
      setEditEmployeeId(null);
      setEditEmployee({ username: '', email: '', position: '' });
    } catch (error) {
      console.error('Error updating employee:', error);
      setError('Error updating employee');
    }
  };

  const handleEdit = (employee) => {
    setEditEmployeeId(employee.id);
    setEditEmployee({ username: employee.username, email: employee.email, position: employee.position });
  };

  const handleDelete = async (employeeId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this employee?');
    if (confirmDelete) {
      try {
        await axiosInstance.delete(`/users/${employeeId}`);
        const sortedEmployees = employees.filter((employee) => employee.id !== employeeId).sort((a, b) => getFirstName(a.username).localeCompare(getFirstName(b.username)));
        setEmployees(sortedEmployees);
      } catch (error) {
        console.error('Error deleting employee:', error);
        setError('Error deleting employee');
      }
    }
  };

  return (
    <Container sx={{ paddingTop: '20px' }}>
      <Box mt={4}>
        <Typography variant="h4" sx={{ marginBottom: '20px' }}>
          Employees
        </Typography>
        {error && (
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        )}
        <Card sx={{ marginBottom: '20px' }}>
          <CardContent>
            <Typography variant="h5" sx={{ marginBottom: '20px' }}>
              Add Employees
            </Typography>
            <form onSubmit={handleSubmit}>
              {newEmployees.map((employee, index) => (
                <Grid container spacing={3} key={index}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Username"
                      name="username"
                      value={employee.username}
                      onChange={(e) => handleChange(index, e)}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Email"
                      name="email"
                      value={employee.email}
                      onChange={(e) => handleChange(index, e)}
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Position"
                      name="position"
                      value={employee.position}
                      onChange={(e) => handleChange(index, e)}
                      required
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ))}
              <CardActions>
                <Grid container spacing={3} sx={{ marginTop: '20px' }}>
                  <Grid item xs={12} sm={6}>
                    <Button type="button" variant="outlined" onClick={handleAddEmployeeField} fullWidth>
                      Add Another Employee
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                      Add Employees
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </form>
          </CardContent>
        </Card>
        <Typography variant="h5" sx={{ marginBottom: '20px' }}>
          Employees List
        </Typography>
        <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableHeaderCell>Username</StyledTableHeaderCell>
                <StyledTableHeaderCell>Email</StyledTableHeaderCell>
                <StyledTableHeaderCell>Position</StyledTableHeaderCell>
                <StyledTableHeaderCell>Actions</StyledTableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((employee) => (
                <TableRow key={employee.id}>
                  <StyledTableCell>
                    {editEmployeeId === employee.id ? (
                      <TextField
                        name="username"
                        value={editEmployee.username}
                        onChange={handleEditChange}
                        fullWidth
                      />
                    ) : (
                      employee.username
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {editEmployeeId === employee.id ? (
                      <TextField
                        name="email"
                        value={editEmployee.email}
                        onChange={handleEditChange}
                        fullWidth
                      />
                    ) : (
                      employee.email
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {editEmployeeId === employee.id ? (
                      <TextField
                        name="position"
                        value={editEmployee.position}
                        onChange={handleEditChange}
                        fullWidth
                      />
                    ) : (
                      employee.position
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {editEmployeeId === employee.id ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleEditSubmit}
                        style={{ marginRight: '10px' }}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEdit(employee)}
                        style={{ marginRight: '10px' }}
                      >
                        Edit
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDelete(employee.id)}
                    >
                      Delete
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default Employees;
