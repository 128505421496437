import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';
import axiosInstance from './axiosConfig';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: theme.typography.fontWeightBold,
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const ProjectNameCell = styled(StyledTableCell)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  backgroundColor: theme.palette.background.paper,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: '10px',
  backgroundColor: theme.palette.background.default,
  '& .MuiInputBase-root': {
    height: '35px',
    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
}));

const BoldTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
  fontWeight: theme.typography.fontWeightBold,
}));

const AddProjectButton = styled(Button)(({ theme }) => ({
  margin: '20px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const UserUtilization = ({ userId }) => {
  const [projects, setProjects] = useState([]);
  const [utilizations, setUtilizations] = useState([]);
  const [editedUtilizations, setEditedUtilizations] = useState({});
  const [newProjectId, setNewProjectId] = useState('');
  const [error, setError] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  useEffect(() => {
    if (userId) {
      loadProjects();
      loadUtilizations(userId);
    }
  }, [userId]);

  const loadProjects = async () => {
    try {
      const response = await axiosInstance.get('/projects/');
      const sortedProjects = response.data.sort((a, b) =>
        `${a.company} ${a.name}`.localeCompare(`${b.company} ${b.name}`)
      );
      setProjects(sortedProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const loadUtilizations = async (userId) => {
    try {
      const response = await axiosInstance.get(`/utilizations/user/${userId}`);
      setUtilizations(response.data);
    } catch (error) {
      console.error('Error fetching utilizations:', error);
      setError('Error fetching utilizations');
    }
  };

  const handleUtilizationChange = (e, projectId, month) => {
    const { value } = e.target;
    setEditedUtilizations((prev) => ({
      ...prev,
      [`${projectId}-${month}`]: value,
    }));
  };

  const handleSave = async (projectId, month) => {
    const utilizationValue = parseFloat(editedUtilizations[`${projectId}-${month}`]);
    if (!isNaN(utilizationValue)) {
      const utilization = {
        project_id: projectId,
        month,
        utilization: utilizationValue,
        user_id: parseInt(userId, 10),
        year: new Date().getFullYear(),
      };

      const existingUtilization = utilizations.find(
        (util) =>
          util.project_id === projectId &&
          util.month === month &&
          util.year === utilization.year &&
          util.user_id === utilization.user_id
      );

      try {
        if (existingUtilization) {
          // Update existing utilization
          await axiosInstance.put(`/utilizations/${existingUtilization.id}`, utilization);
          setUtilizations((prev) =>
            prev.map((util) =>
              util.id === existingUtilization.id ? { ...util, utilization: utilizationValue } : util
            )
          );
        } else {
          // Create new utilization
          const response = await axiosInstance.post('/utilizations/', [utilization]);
          setUtilizations((prev) => [...prev, ...response.data]);
        }
      } catch (error) {
        console.error('Error saving utilization:', error);
        setError('Error saving utilization');
      }
    }
  };

  const handleAddProject = async () => {
    if (newProjectId) {
      const newUtilizations = monthNames.map((month) => ({
        project_id: parseInt(newProjectId, 10),
        month,
        utilization: 0,
        user_id: parseInt(userId, 10),
        year: new Date().getFullYear(),
      }));

      try {
        const response = await axiosInstance.post('/utilizations/', newUtilizations);
        setUtilizations((prev) => [...prev, ...response.data]);
        setIsDialogOpen(false);
      } catch (error) {
        console.error('Error adding project utilization:', error);
        setError('Error adding project utilization');
      }
    }
  };

  const handleDeleteProjectUtilizations = async () => {
    try {
      const projectUtilizations = utilizations.filter((util) => util.project_id === projectToDelete);
      await Promise.all(
        projectUtilizations.map(async (util) => {
          await axiosInstance.delete(`/utilizations/${util.id}`);
        })
      );
      setUtilizations(utilizations.filter((util) => util.project_id !== projectToDelete));
      setIsDeleteDialogOpen(false);
      setProjectToDelete(null);
    } catch (error) {
      console.error('Error deleting project utilizations:', error);
      setError('Error deleting project utilizations');
    }
  };

  const getTotalUtilization = (month) => {
    const total = utilizations
      .filter((util) => util.month === month)
      .reduce((total, util) => total + (util.utilization || 0), 0);
    return total.toFixed(2);
  };

  return (
    <Box mt={4}>
      {error && (
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      )}
      <Typography variant="h6" sx={{ marginBottom: '20px' }}>
        Project Utilization
      </Typography>
      <TableContainer component={Paper} sx={{ marginTop: '20px', maxHeight: '70vh', overflowY: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell>Project Name</StyledTableHeaderCell>
              {monthNames.map((month) => (
                <StyledTableHeaderCell key={month}>{month}</StyledTableHeaderCell>
              ))}
              <StyledTableHeaderCell>Actions</StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects
              .filter((project) =>
                utilizations.some((util) => util.project_id === project.id)
              )
              .map((project) => (
                <TableRow key={project.id}>
                  <ProjectNameCell>{`${project.company} ${project.name}`}</ProjectNameCell>
                  {monthNames.map((month) => {
                    const utilization = utilizations.find(
                      (util) =>
                        util.project_id === project.id &&
                        util.month === month &&
                        util.user_id === parseInt(userId, 10)
                    );
                    return (
                      <StyledTableCell key={month}>
                        <StyledTextField
                          type="number"
                          value={
                            editedUtilizations[`${project.id}-${month}`] ??
                            (utilization ? utilization.utilization : 0)
                          }
                          onChange={(e) =>
                            handleUtilizationChange(e, project.id, month)
                          }
                          onBlur={() => handleSave(project.id, month)}
                          InputProps={{
                            inputProps: { min: 0, max: 1, step: 0.05 },
                          }}
                        />
                      </StyledTableCell>
                    );
                  })}
                  <StyledTableCell>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        setProjectToDelete(project.id);
                        setIsDeleteDialogOpen(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </TableRow>
              ))}
            <TableRow>
              <BoldTableCell>Total Utilization</BoldTableCell>
              {monthNames.map((month) => (
                <BoldTableCell key={`total-${month}`}>
                  {getTotalUtilization(month)}
                </BoldTableCell>
              ))}
              <BoldTableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <AddProjectButton
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setIsDialogOpen(true)}
      >
        Add Project
      </AddProjectButton>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Select Project</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Project</InputLabel>
            <Select
              value={newProjectId}
              onChange={(e) => setNewProjectId(e.target.value)}
            >
              {projects.map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {`${project.company} ${project.name}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddProject} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete all utilizations for this project?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteProjectUtilizations} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserUtilization;
