import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from './axiosConfig';

const UserPDTs = ({ userId }) => {
  const [pdts, setPdts] = useState([]);
  const [newPDT, setNewPDT] = useState({
    goal: '',
    percentage: '',
    fulfilled_percentage: '',
    comment: '',
    year: new Date().getFullYear(), // Default to current year
  });
  const [editPDT, setEditPDT] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [pdtToDelete, setPdtToDelete] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    loadPDTs();
  }, [userId]);

  const loadPDTs = async () => {
    try {
      const response = await axiosInstance.get(`/pdts/${userId}`);
      setPdts(response.data);
    } catch (error) {
      console.error('Error fetching PDTs:', error);
      setError('Error fetching PDTs');
    }
  };

  const handleAddPDT = async () => {
    const pdt = {
      ...newPDT,
      user_id: parseInt(userId, 10),
    };

    try {
      const response = await axiosInstance.post('/pdts/', [pdt]);
      setPdts([...pdts, ...response.data]);
      setNewPDT({
        goal: '',
        percentage: '',
        fulfilled_percentage: '',
        comment: '',
        year: new Date().getFullYear(), // Reset to current year
      });
      setIsDialogOpen(false);
    } catch (error) {
      console.error('Error adding PDT:', error);
      setError('Error adding PDT');
    }
  };

  const handleUpdatePDT = async (id) => {
    try {
      const response = await axiosInstance.put(`/pdts/${id}`, editPDT);
      setPdts(pdts.map((pdt) => (pdt.id === id ? response.data : pdt)));
      setEditPDT(null);
    } catch (error) {
      console.error('Error updating PDT:', error);
      setError('Error updating PDT');
    }
  };

  const handleDeletePDT = async () => {
    try {
      await axiosInstance.delete(`/pdts/${pdtToDelete}`);
      setPdts(pdts.filter((pdt) => pdt.id !== pdtToDelete));
      setIsDeleteDialogOpen(false);
      setPdtToDelete(null);
    } catch (error) {
      console.error('Error deleting PDT:', error);
      setError('Error deleting PDT');
    }
  };

  const groupedPdtsByYear = pdts.reduce((acc, pdt) => {
    const { year } = pdt;
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(pdt);
    return acc;
  }, {});

  const sortedYears = Object.keys(groupedPdtsByYear).sort((a, b) => b - a);

  return (
    <Box mt={4}>
      {error && (
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      )}
      <Typography variant="h5" sx={{ marginBottom: '20px' }}>
        PDT Goals
      </Typography>
      {sortedYears.map((year) => (
        <Box key={year} sx={{ marginBottom: '40px' }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {year}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Goal</TableCell>
                  <TableCell>Percentage</TableCell>
                  <TableCell>Fulfilled Percentage</TableCell>
                  <TableCell>Comment</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedPdtsByYear[year].map((pdt) => (
                  <TableRow key={pdt.id}>
                    <TableCell>{pdt.goal}</TableCell>
                    <TableCell>{pdt.percentage}</TableCell>
                    <TableCell>{pdt.fulfilled_percentage}</TableCell>
                    <TableCell>{pdt.comment}</TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => setEditPDT(pdt)}
                      >
                        <AddIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => {
                          setPdtToDelete(pdt.id);
                          setIsDeleteDialogOpen(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setIsDialogOpen(true)}
        sx={{ marginTop: '20px' }}
      >
        Add PDT
      </Button>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Add PDT</DialogTitle>
        <DialogContent>
        <TextField
            label="Year"
            type="number"
            value={newPDT.year}
            onChange={(e) => setNewPDT({ ...newPDT, year: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Goal"
            value={newPDT.goal}
            onChange={(e) => setNewPDT({ ...newPDT, goal: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Percentage"
            type="number"
            value={newPDT.percentage}
            onChange={(e) => setNewPDT({ ...newPDT, percentage: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Fulfilled Percentage"
            type="number"
            value={newPDT.fulfilled_percentage}
            onChange={(e) => setNewPDT({ ...newPDT, fulfilled_percentage: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Comment"
            value={newPDT.comment}
            onChange={(e) => setNewPDT({ ...newPDT, comment: e.target.value })}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddPDT} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {editPDT && (
        <Dialog open={true} onClose={() => setEditPDT(null)}>
          <DialogTitle>Edit PDT</DialogTitle>
          <DialogContent>
            <TextField
              label="Goal"
              value={editPDT.goal}
              onChange={(e) => setEditPDT({ ...editPDT, goal: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Percentage"
              type="number"
              value={editPDT.percentage}
              onChange={(e) => setEditPDT({ ...editPDT, percentage: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Fulfilled Percentage"
              type="number"
              value={editPDT.fulfilled_percentage}
              onChange={(e) => setEditPDT({ ...editPDT, fulfilled_percentage: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Comment"
              value={editPDT.comment}
              onChange={(e) => setEditPDT({ ...editPDT, comment: e.target.value })}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Year"
              type="number"
              value={editPDT.year}
              onChange={(e) => setEditPDT({ ...editPDT, year: e.target.value })}
              fullWidth
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditPDT(null)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleUpdatePDT(editPDT.id)} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this PDT?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeletePDT} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserPDTs;
