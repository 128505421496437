import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material';
import UserPDTs from './UserPDTs';
import UserCertificates from './UserCertificates';
import UserFeedbacks from './UserFeedbacks';
import UserUtilization from './UserUtilization';
import UserCompetences from './UserCompetences'; // Import the new component
import axiosInstance from './axiosConfig';

const UserManagement = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (userId) {
      loadUser(userId);
    }
  }, [userId]);

  const loadUser = async (userId) => {
    try {
      const response = await axiosInstance.get(`/users/${userId}`);
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ paddingTop: '20px', paddingLeft: 15, paddingRight: 15 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h5">User Management: {user ? user.username : 'User'}</Typography>
        </Toolbar>
      </AppBar>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="user management tabs">
        <Tab label="Utilization" />
        <Tab label="PDT Goals" />
        <Tab label="Certificates" />
        <Tab label="Feedbacks" />
        <Tab label="Competences" /> {/* Add new tab */}
      </Tabs>
      <Box mt={4}>
        {tabValue === 0 && <UserUtilization userId={userId} />}
        {tabValue === 1 && <UserPDTs userId={userId} />}
        {tabValue === 2 && <UserCertificates userId={userId} />}
        {tabValue === 3 && <UserFeedbacks userId={userId} />}
        {tabValue === 4 && <UserCompetences userId={userId} />} {/* Add new tab content */}
      </Box>
    </Box>
  );
};

export default UserManagement;
