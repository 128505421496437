export const msalConfig = {
  auth: {
    clientId: "9c4ec188-2571-4d4c-9c03-cf3d818770ce",
    authority: "https://login.microsoftonline.com/38ea53fb-9117-4764-adc6-31f828910b30",
    redirectUri: "https://devops.p3ds.net/",
   //redirectUri: "http://localhost:3000/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ["User.Read"],
};
