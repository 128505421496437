import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import axiosInstance from './axiosConfig';

const LandingPage = () => {
  const { accounts } = useMsal();
  const account = accounts[0];
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    const getUserDataAndRedirect = async () => {
      console.log("LandingPage: Rendered with account", account);

      if (!account) {
        console.log("LandingPage: No account found, redirecting to login");
        setRedirect(<Navigate to="/login" />);
        return;
      }

      const userEmail = account.username.toLowerCase();
      console.log(`LandingPage: User email from account (converted to lowercase): ${userEmail}`);

      try {
        const response = await axiosInstance.get('/users/');
        console.log("LandingPage: Response from /users endpoint:", response.data);

        const users = response.data;
        const user = users.find((u) => u.email.toLowerCase() === userEmail);

        if (!user) {
          console.log("LandingPage: No user found with email:", userEmail);
          setRedirect(<Navigate to="/access-denied" />);
          return;
        }

        console.log("LandingPage: Found user:", user);

        const userRoles = account.idTokenClaims.roles || [];
        const isAdmin = userRoles.includes('Admin');
        const isUser = userRoles.includes('User');

        console.log("LandingPage: User roles:", userRoles);
        console.log("LandingPage: isAdmin:", isAdmin);
        console.log("LandingPage: isUser:", isUser);

        if (isAdmin) {
          console.log("LandingPage: Redirecting to /overview");
          setRedirect(<Navigate to="/overview" />);
        } else if (isUser) {
          console.log(`LandingPage: Redirecting to /user-dashboard/${user.id}`);
          setRedirect(<Navigate to={`/user-dashboard/${user.id}`} />);
        } else {
          console.log("LandingPage: User does not have the required roles, redirecting to /access-denied");
          setRedirect(<Navigate to="/access-denied" />);
        }
      } catch (error) {
        console.error('LandingPage: Error fetching users:', error);
        setRedirect(<Navigate to="/access-denied" />);
      }
    };

    getUserDataAndRedirect();
  }, [account]);

  return (
    <>
      {redirect}
      {!redirect && <div>Loading...</div>}
    </>
  );
};

export default LandingPage;
