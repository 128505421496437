import axios from 'axios';
import { loginRequest } from './authConfig';
import {msalInstance} from './index'

const axiosInstance = axios.create({
  baseURL: 'https://devops.p3ds.net/backend/',
  //baseURL: 'http://localhost:8000/',
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      const account = accounts[0];
      try {
        const response = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account: account,
        });
        const token = response.idToken;
        console.log('Access Token Length:', token.length); // Log token length for debugging
        config.headers['Authorization'] = `Bearer ${token}`;
      } catch (error) {
        console.error('Error acquiring token silently:', error);
         if (error instanceof msalInstance.InteractionRequiredAuthError) {
          // Fallback to interactive login if silent acquisition fails
          try {
            const response = await msalInstance.acquireTokenPopup(loginRequest);
            const token = response.idToken;
            console.log('Access Token Length (interactive):', token.length); // Log token length for debugging
            config.headers['Authorization'] = `Bearer ${token}`;
          } catch (interactiveError) {
            console.error('Error acquiring token interactively:', interactiveError);
          }
        }
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
