import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CssBaseline, Box, Container, ThemeProvider } from '@mui/material';
import { GlobalStyles } from '@mui/system';
import Sidebar from './Sidebar';
import Employees from './Employees';
import Projects from './Projects';
import Certificates from './Certificates';
import Overview from './Overview';
import UserManagement from './UserManagement';
import Utilization from './Utilization';
import UserDashboard from './UserDashboard';
import theme from './theme';
import ProtectedRoute from './ProtectedRoute';
import Login from './Login';
import AccessDenied from './AccessDenied';
import LandingPage from './LandingPage';
import { useMsal } from '@azure/msal-react';

const globalStyles = (
  <GlobalStyles
    styles={{
      '*::-webkit-scrollbar': { display: 'none' },
      '*': { scrollbarWidth: 'none' },
      body: { margin: 0, padding: 0 },
      html: { WebkitFontSmoothing: 'auto' },
    }}
  />
);

const App = () => {
  const { accounts } = useMsal();
  const account = accounts[0];
  console.log("App: Rendering application");
  console.log("App: Accounts", accounts);
  console.log("App: IsAuthenticated", !!account);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {globalStyles}
      <Router>
        <Box sx={{ display: 'flex' }}>
          {account && <Sidebar />}
          <Box 
            component="main" 
            sx={{ 
              flexGrow: 1, 
              p: 3, 
              paddingLeft: '0 !important', 
              paddingRight: '0 !important', 
              margin: '0 10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              backgroundColor: '#ffffff',
              borderRadius: '8px'
            }}
          >
            <Container maxWidth={false} sx={{ paddingLeft: '0 !important', paddingRight: '0 !important' }}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/access-denied" element={<AccessDenied />} />
                <Route path="/employees" element={<ProtectedRoute allowedRoles={['Admin']}><Employees /></ProtectedRoute>} />
                <Route path="/projects" element={<ProtectedRoute allowedRoles={['Admin']}><Projects /></ProtectedRoute>} />
                <Route path="/utilization" element={<ProtectedRoute allowedRoles={['Admin']}><Utilization /></ProtectedRoute>} />
                <Route path="/certificates" element={<ProtectedRoute allowedRoles={['Admin']}><Certificates /></ProtectedRoute>} />
                <Route path="/overview" element={<ProtectedRoute allowedRoles={['Admin']}><Overview /></ProtectedRoute>} />
                <Route path="/user/:userId" element={<ProtectedRoute allowedRoles={['Admin']}><UserManagement /></ProtectedRoute>} />
                <Route path="/user-dashboard/:userId" element={<ProtectedRoute allowedRoles={['Admin','User']}><UserDashboard /></ProtectedRoute>} />
                <Route path="/" element={<LandingPage />} />
              </Routes>
            </Container>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
};

export default App;
