import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { accounts } = useMsal();
  const account = accounts[0];

  console.log("ProtectedRoute: isAuthenticated", !!account);
  console.log("ProtectedRoute: accounts", accounts);

  if (!account) {
    console.log("ProtectedRoute: Not authenticated, redirecting to login");
    return <Navigate to="/login" />;
  }

  const userRoles = account.idTokenClaims.roles || [];
  const isAuthorized = allowedRoles.some(role => userRoles.includes(role));

  console.log("ProtectedRoute: User roles", userRoles);
  console.log("ProtectedRoute: Allowed roles", allowedRoles);
  console.log("ProtectedRoute: isAuthorized", isAuthorized);

  if (!isAuthorized) {
    console.log("ProtectedRoute: Access denied, redirecting to access denied page");
    return <Navigate to="/access-denied" />;
  }

  return children;
};

export default ProtectedRoute;
