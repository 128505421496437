import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
} from '@mui/material';
import { styled } from '@mui/system';
import axiosInstance from './axiosConfig';
import UserCompetences from './UserCompetences'; // Import the UserCompetences component

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: theme.typography.fontWeightBold,
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const ProjectNameCell = styled(StyledTableCell)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  backgroundColor: theme.palette.background.paper,
}));

const BoldTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
  fontWeight: theme.typography.fontWeightBold,
}));

const UserDashboard = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [projects, setProjects] = useState([]);
  const [utilizations, setUtilizations] = useState([]);
  const [pdts, setPdts] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [error, setError] = useState('');
  const [tabIndex, setTabIndex] = useState(0);

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  useEffect(() => {
    if (userId) {
      loadUser(userId);
      loadProjects();
      loadUtilizations(userId);
      loadPDTs(userId);
      loadCertificates(userId);
      loadFeedbacks(userId);
    }
  }, [userId]);

  const loadUser = async (userId) => {
    try {
      const response = await axiosInstance.get(`/users/${userId}`);
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const loadProjects = async () => {
    try {
      const response = await axiosInstance.get('/projects/');
      const sortedProjects = response.data.sort((a, b) =>
        `${a.company} ${a.name}`.localeCompare(`${b.company} ${b.name}`)
      );
      setProjects(sortedProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const loadUtilizations = async (userId) => {
    try {
      const response = await axiosInstance.get(`/utilizations/user/${userId}`);
      setUtilizations(response.data);
    } catch (error) {
      console.error('Error fetching utilizations:', error);
      setError('Error fetching utilizations');
    }
  };

  const loadPDTs = async (userId) => {
    try {
      const response = await axiosInstance.get(`/pdts/${userId}`);
      setPdts(response.data);
    } catch (error) {
      console.error('Error fetching PDTs:', error);
      setError('Error fetching PDTs');
    }
  };

  const loadCertificates = async (userId) => {
    try {
      const response = await axiosInstance.get(`/users/${userId}/certificates`);
      setCertificates(response.data);
    } catch (error) {
      console.error('Error fetching certificates:', error);
      setError('Error fetching certificates');
    }
  };

  const loadFeedbacks = async (userId) => {
    try {
      const response = await axiosInstance.get(`/users/${userId}/feedbacks`);
      setFeedbacks(response.data);
    } catch (error) {
      console.error('Error fetching feedbacks:', error);
      setError('Error fetching feedbacks');
    }
  };

  const getTotalUtilization = (month) => {
    return utilizations
      .filter((util) => util.month === month)
      .reduce((total, util) => total + (util.utilization || 0), 0)
      .toFixed(2);
  };

  const groupedPdtsByYear = pdts.reduce((acc, pdt) => {
    const { year } = pdt;
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(pdt);
    return acc;
  }, {});

  const sortedYears = Object.keys(groupedPdtsByYear).sort((a, b) => b - a);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Box sx={{ paddingTop: '20px', paddingLeft: 15, paddingRight: 15 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h5">User Dashboard: {user ? user.username : 'User'}</Typography>
        </Toolbar>
      </AppBar>
      <Tabs value={tabIndex} onChange={handleChange} centered>
        <Tab label="Project Utilization" />
        <Tab label="PDT Goals" />
        <Tab label="Certificates" />
        <Tab label="Feedbacks" />
        <Tab label="Competences" /> {/* Added a new tab for Competences */}
      </Tabs>
      {tabIndex === 0 && (
        <Box mt={4}>
          {error && (
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          )}
          <Typography variant="h6" sx={{ marginBottom: '20px' }}>
            Project Utilization
          </Typography>
          <TableContainer component={Paper} sx={{ marginTop: '20px', maxHeight: '70vh', overflowY: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableHeaderCell>Project Name</StyledTableHeaderCell>
                  {monthNames.map((month) => (
                    <StyledTableHeaderCell key={month}>{month}</StyledTableHeaderCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {projects
                  .filter((project) =>
                    utilizations.some((util) => util.project_id === project.id)
                  )
                  .map((project) => (
                    <TableRow key={project.id}>
                      <ProjectNameCell>{`${project.company} ${project.name}`}</ProjectNameCell>
                      {monthNames.map((month) => {
                        const utilization = utilizations.find(
                          (util) =>
                            util.project_id === project.id &&
                            util.month === month &&
                            util.user_id === parseInt(userId, 10)
                        );
                        return (
                          <StyledTableCell key={month}>
                            {utilization ? utilization.utilization : 0}
                          </StyledTableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                <TableRow>
                  <BoldTableCell>Total Utilization</BoldTableCell>
                  {monthNames.map((month) => (
                    <BoldTableCell key={`total-${month}`}>
                      {getTotalUtilization(month)}
                    </BoldTableCell>
                  ))}
                  <BoldTableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {tabIndex === 1 && (
        <Box mt={4}>
          {sortedYears.map((year) => (
            <Box key={year} sx={{ marginBottom: '40px' }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableHeaderCell colSpan={5} align="center">
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                          {year}
                        </Typography>
                      </StyledTableHeaderCell>
                    </TableRow>
                    <TableRow>
                      <StyledTableHeaderCell>Goal</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Percentage</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Fulfilled Percentage</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Comment</StyledTableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupedPdtsByYear[year].map((pdt) => (
                      <TableRow key={pdt.id}>
                        <StyledTableCell>{pdt.goal}</StyledTableCell>
                        <StyledTableCell>{pdt.percentage}</StyledTableCell>
                        <StyledTableCell>{pdt.fulfilled_percentage}</StyledTableCell>
                        <StyledTableCell>{pdt.comment}</StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))}
        </Box>
      )}
      {tabIndex === 2 && (
        <Box mt={4}>
          <Typography variant="h6" sx={{ marginBottom: '20px' }}>
            Certificates
          </Typography>
          <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableHeaderCell>Certificate Name</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Status</StyledTableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {certificates.map((certificate) => (
                  <TableRow key={certificate.id}>
                    <StyledTableCell>{certificate.certificate_name}</StyledTableCell>
                    <StyledTableCell>{certificate.status}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {tabIndex === 3 && (
        <Box mt={4}>
          <Typography variant="h6" sx={{ marginBottom: '20px' }}>
            Feedbacks
          </Typography>
          <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableHeaderCell>Date</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Feedback Text</StyledTableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {feedbacks.map((feedback) => (
                  <TableRow key={feedback.id}>
                    <StyledTableCell>{feedback.date}</StyledTableCell>
                    <StyledTableCell>{feedback.feedback_text}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {tabIndex === 4 && (  // Add condition for the Competences tab
        <UserCompetences userId={userId} />
      )}
    </Box>
  );
};

export default UserDashboard;
