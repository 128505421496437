import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#333333',  // Dark gray
    },
    secondary: {
      main: '#ff4081',
    },
    background: {
      default: '#f5f5f5',  // Light gray
    },
    text: {
      primary: '#000000',  // Black
      secondary: '#757575',  // Medium gray
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h4: {
      fontWeight: 'bold',
      fontSize: '1.5rem', // Reduced font size
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '1.25rem', // Reduced font size
    },
    body1: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: '#333333',  // Dark gray
          color: '#ffffff',  // White
          fontWeight: 'bold',
          textAlign: 'center',
          border: '1px solid #e0e0e0',
        },
        body: {
          textAlign: 'center',
          border: '1px solid #e0e0e0',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '10px',
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginBottom: '20px',
        },
        containedPrimary: {
          backgroundColor: '#333333',  // Dark gray
          color: '#ffffff',  // White
          '&:hover': {
            backgroundColor: '#000000',  // Black
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: '#f9f9f9',
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#ffffff',
          },
        },
      },
    },
  },
});

export default theme;
