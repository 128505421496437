import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  Container,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableSortLabel,
  styled,
} from '@mui/material';
import axiosInstance from './axiosConfig';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: theme.typography.fontWeightBold,
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const Certificates = () => {
  const [certificates, setCertificates] = useState([]);
  const [users, setUsers] = useState([]);
  const [newCertificate, setNewCertificate] = useState({
    name: '',
    technology: '',
  });
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [filterByTechnology, setFilterByTechnology] = useState('');
  const [filterByName, setFilterByName] = useState('');
  const [totalPassed, setTotalPassed] = useState(0);
  const [totalInProgress, setTotalInProgress] = useState(0);

  useEffect(() => {
    loadCertificates();
    loadUsers();
  }, [sortBy, sortOrder, filterByTechnology, filterByName]);

  const loadCertificates = async () => {
    try {
      const response = await axiosInstance.get('/certificates/', {
        params: {
          sort_by: sortBy,
          sort_order: sortOrder,
          filter_by_technology: filterByTechnology,
          filter_by_name: filterByName,
        },
      });
      setCertificates(response.data);
      calculateTotals(response.data);
    } catch (error) {
      console.error('Error fetching certificates:', error);
    }
  };

  const loadUsers = async () => {
    try {
      const response = await axiosInstance.get('/users/');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCertificate((prevCertificate) => ({ ...prevCertificate, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/certificates/', [newCertificate]);
      setCertificates((prevCertificates) => [...prevCertificates, ...response.data]);
      setNewCertificate({ name: '', technology: '' });
    } catch (error) {
      console.error('Error adding certificate:', error);
    }
  };

  const handleSortChange = (property) => {
    const isAsc = sortBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleFilterTechnologyChange = (e) => {
    setFilterByTechnology(e.target.value);
  };

  const handleFilterNameChange = (e) => {
    setFilterByName(e.target.value);
  };

  const getCertificateStats = (certificateName) => {
    const passedUsers = users.filter(user =>
      user.certificates.some(cert => cert.certificate_name === certificateName && cert.status === 'Passed')
    );
    const inProgressUsers = users.filter(user =>
      user.certificates.some(cert => cert.certificate_name === certificateName && cert.status === 'In Progress')
    );
    return {
      passed: passedUsers.length,
      inProgress: inProgressUsers.length,
      passedUsers: passedUsers.map(user => user.username),
      inProgressUsers: inProgressUsers.map(user => user.username),
    };
  };

  const calculateTotals = (certificates) => {
    let passedCount = 0;
    let inProgressCount = 0;

    certificates.forEach(certificate => {
      const { passed, inProgress } = getCertificateStats(certificate.name);
      passedCount += passed;
      inProgressCount += inProgress;
    });

    setTotalPassed(passedCount);
    setTotalInProgress(inProgressCount);
  };

  const uniqueTechnologies = [...new Set(certificates.map(cert => cert.technology))];
  const uniqueNames = [...new Set(certificates.map(cert => cert.name))];

  return (
    <Container sx={{ paddingTop: '20px' }}>
      <Box mt={4}>
        <Typography variant="h4" sx={{ marginBottom: '20px' }}>
          Certificates
        </Typography>
        <form onSubmit={handleSubmit} sx={{ marginBottom: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Certificate Name"
                name="name"
                value={newCertificate.name}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Technology"
                name="technology"
                value={newCertificate.technology}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Add Certificate
              </Button>
            </Grid>
          </Grid>
        </form>
        <Typography variant="h5" sx={{ marginBottom: '20px' }}>
          Certificates List
        </Typography>
        <Grid container spacing={3} sx={{ marginBottom: '20px' }}>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel>Sort By</InputLabel>
              <Select value={sortBy} onChange={(e) => handleSortChange(e.target.value)}>
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="technology">Technology</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel>Sort Order</InputLabel>
              <Select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel>Filter by Technology</InputLabel>
              <Select value={filterByTechnology} onChange={handleFilterTechnologyChange}>
                <MenuItem value=""><em>None</em></MenuItem>
                {uniqueTechnologies.map((tech) => (
                  <MenuItem key={tech} value={tech}>{tech}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel>Filter by Certificate</InputLabel>
              <Select value={filterByName} onChange={handleFilterNameChange}>
                <MenuItem value=""><em>None</em></MenuItem>
                {uniqueNames.map((name) => (
                  <MenuItem key={name} value={name}>{name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableHeaderCell sortDirection={sortBy === 'name' ? sortOrder : false}>
                  <TableSortLabel
                    active={sortBy === 'name'}
                    direction={sortBy === 'name' ? sortOrder : 'asc'}
                    onClick={() => handleSortChange('name')}
                  >
                    Certificate Name
                  </TableSortLabel>
                </StyledTableHeaderCell>
                <StyledTableHeaderCell sortDirection={sortBy === 'technology' ? sortOrder : false}>
                  <TableSortLabel
                    active={sortBy === 'technology'}
                    direction={sortBy === 'technology' ? sortOrder : 'asc'}
                    onClick={() => handleSortChange('technology')}
                  >
                    Technology
                  </TableSortLabel>
                </StyledTableHeaderCell>
                <StyledTableHeaderCell>Passed</StyledTableHeaderCell>
                <StyledTableHeaderCell>In Progress</StyledTableHeaderCell>
                <StyledTableHeaderCell>Passed Users</StyledTableHeaderCell>
                <StyledTableHeaderCell>In Progress Users</StyledTableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {certificates.map((certificate) => {
                const { passed, inProgress, passedUsers, inProgressUsers } = getCertificateStats(certificate.name);
                return (
                  <TableRow key={certificate.id}>
                    <StyledTableCell>{certificate.name}</StyledTableCell>
                    <StyledTableCell>{certificate.technology}</StyledTableCell>
                    <StyledTableCell>{passed}</StyledTableCell>
                    <StyledTableCell>{inProgress}</StyledTableCell>
                    <StyledTableCell>
                      {passedUsers.map((user, index) => (
                        <div key={index}>{user}</div>
                      ))}
                    </StyledTableCell>
                    <StyledTableCell>
                      {inProgressUsers.map((user, index) => (
                        <div key={index}>{user}</div>
                      ))}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={4}>
          <Typography variant="h6">
            Total Passed: {totalPassed}
          </Typography>
          <Typography variant="h6">
            Total In Progress: {totalInProgress}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Certificates;
