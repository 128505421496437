import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import CssBaseline from '@mui/material/CssBaseline';
import { msalConfig } from './authConfig';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

export const msalInstance = new PublicClientApplication(msalConfig);

console.log("Initializing MSAL instance", msalInstance);

async function initializeMsal() {
  try {
    await msalInstance.initialize();
    const tokenResponse = await msalInstance.handleRedirectPromise();
    if (tokenResponse) {
      console.log("Token Response:", tokenResponse);
    } else {
      console.log("No Token Response");
    }
  } catch (error) {
    console.error("Error handling redirect promise:", error);
  }
}

initializeMsal().then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </ThemeProvider>
  );
});
