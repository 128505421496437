import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  Box,
  AppBar,
  Toolbar,
  Grid,
} from '@mui/material';
import { styled } from '@mui/system';
import axiosInstance from './axiosConfig';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const Overview = () => {
  const [utilizations, setUtilizations] = useState([]);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [error, setError] = useState('');

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const currentDate = new Date();
  const currentMonthIndex = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const currentMonth = monthNames[currentMonthIndex];
  const monthsToDisplay = monthNames.slice(currentMonthIndex, currentMonthIndex + 3);

  useEffect(() => {
    loadUtilizations();
    loadUsers();
    loadProjects();
    loadCertificates();
  }, []);

  const loadUtilizations = async () => {
    try {
      const response = await axiosInstance.get('/utilizations/');
      setUtilizations(response.data.filter(utilization => utilization.year === currentYear));
    } catch (error) {
      console.error('Error fetching utilizations:', error);
      setError('Error fetching utilizations');
    }
  };

  const loadUsers = async () => {
    try {
      const response = await axiosInstance.get('/users/');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Error fetching users');
    }
  };

  const loadProjects = async () => {
    try {
      const response = await axiosInstance.get('/projects/');
      setProjects(response.data);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError('Error fetching projects');
    }
  };

  const loadCertificates = async () => {
    try {
      const response = await axiosInstance.get('/certificates/');
      setCertificates(response.data);
    } catch (error) {
      console.error('Error fetching certificates:', error);
      setError('Error fetching certificates');
    }
  };

  const getUserName = (userId) => {
    const user = users.find((u) => u.id === userId);
    return user ? user.username : 'Unknown';
  };

  const getProjectName = (projectId) => {
    const project = projects.find((p) => p.id === projectId);
    return project ? `${project.company} ${project.name}` : 'Unknown';
  };

  const groupedUtilizations = utilizations.reduce((acc, utilization) => {
    const { user_id, month, utilization: util, project_id } = utilization;
    if (!acc[user_id]) {
      acc[user_id] = { months: {}, projects: {} };
    }
    if (!acc[user_id].months[month]) {
      acc[user_id].months[month] = 0;
    }
    if (!acc[user_id].projects[project_id]) {
      acc[user_id].projects[project_id] = 0;
    }
    acc[user_id].months[month] += util;
    acc[user_id].projects[project_id] += util;
    return acc;
  }, {});

  const usersWithLessUtilization = users.filter(user => {
    const userUtilizations = groupedUtilizations[user.id] || { months: {} };
    return monthsToDisplay.some(month => (userUtilizations.months[month] || 0) < 1);
  });

  const getCertificateStats = () => {
    return certificates.reduce((acc, certificate) => {
      const passedUsers = users.filter(user =>
        user.certificates.some(cert => cert.certificate_name === certificate.name && cert.status === 'Passed')
      );
      if (!acc[certificate.technology]) {
        acc[certificate.technology] = { totalPassed: 0 };
      }
      acc[certificate.technology].totalPassed += passedUsers.length;
      return acc;
    }, {});
  };

  const certificateStats = getCertificateStats();

  const projectsWithCurrentMonthUtilization = projects.filter(project => {
    const totalUtilization = utilizations
      .filter(util => util.project_id === project.id && util.month === currentMonth)
      .reduce((sum, util) => sum + util.utilization, 0);
    return totalUtilization > 0;
  });

  return (
    <Container sx={{ paddingTop: '20px' }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Overview</Typography>
        </Toolbar>
      </AppBar>
      <Box mt={4}>
        {error && (
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" sx={{ marginBottom: '20px' }}>
              Total Utilization per Project
            </Typography>
            <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHeaderCell>Company Project Name</StyledTableHeaderCell>
                    <StyledTableHeaderCell>Total Utilization</StyledTableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectsWithCurrentMonthUtilization.map(project => {
                    const totalUtilization = utilizations
                      .filter(util => util.project_id === project.id && util.month === currentMonth)
                      .reduce((sum, util) => sum + util.utilization, 0);
                    return (
                      <TableRow key={project.id}>
                        <StyledTableCell>{`${project.company} ${project.name}`}</StyledTableCell>
                        <StyledTableCell>{totalUtilization.toFixed(2)}</StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" sx={{ marginBottom: '20px' }}>
              Underutilized Employees
            </Typography>
            <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHeaderCell>Employee Name</StyledTableHeaderCell>
                    <StyledTableHeaderCell>Employee Position</StyledTableHeaderCell>
                    {monthsToDisplay.map(month => (
                      <StyledTableHeaderCell key={month}>{month}</StyledTableHeaderCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersWithLessUtilization.map(user => (
                    <TableRow key={user.id}>
                      <StyledTableCell>{user.username}</StyledTableCell>
                      <StyledTableCell>{user.position}</StyledTableCell>
                      {monthsToDisplay.map(month => (
                        <StyledTableCell key={month}>
                          {groupedUtilizations[user.id] && groupedUtilizations[user.id].months[month] !== undefined ? (
                            <div>
                              {Math.max(1 - (groupedUtilizations[user.id].months[month] || 0), 0).toFixed(2)}
                            </div>
                          ) : (
                            <div>1.00</div>
                          )}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ marginTop: '40px', marginBottom: '20px' }}>
              Total Passed Certificates per Technology
            </Typography>
            <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHeaderCell>Technology</StyledTableHeaderCell>
                    <StyledTableHeaderCell>Total Passed</StyledTableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(certificateStats).map(technology => (
                    <TableRow key={technology}>
                      <StyledTableCell>{technology}</StyledTableCell>
                      <StyledTableCell>{certificateStats[technology].totalPassed}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Overview;
