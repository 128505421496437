import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import { People, Work, BarChart, Person, ExpandLess, ExpandMore, AdminPanelSettings, Assessment, Home, ManageAccounts } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import axiosInstance from './axiosConfig';
import { useMsal } from '@azure/msal-react';

// Define menu items configuration
const menuConfig = {
  Admin: [
    { text: 'Overview', icon: <BarChart />, path: '/overview' },
    { text: 'Utilization', icon: <Assessment />, path: '/utilization' },
    {
      text: 'Admin',
      icon: <AdminPanelSettings />,
      children: [
        { text: 'Employees', icon: <ManageAccounts />, path: '/employees' },
        { text: 'Projects', icon: <Work />, path: '/projects' },
        { text: 'Certificates', icon: <Home />, path: '/certificates' },
      ]
    },
    {
      text: 'Employee Management',
      icon: <People />,
      children: [] // Will be dynamically filled with user links
    }
  ],
  User: []
};

const Sidebar = () => {
  const { accounts } = useMsal();
  const account = accounts[0];
  const [users, setUsers] = useState([]);
  const [adminOpen, setAdminOpen] = useState(false);
  const [userManagementOpen, setUserManagementOpen] = useState(false);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      const response = await axiosInstance.get('/users/');
      const sortedUsers = response.data.sort((a, b) => getFirstName(a.username).localeCompare(getFirstName(b.username)));
      setUsers(sortedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const getFirstName = (username) => {
    const parts = username.split(' ');
    return parts[0];
  };

  const handleAdminToggle = () => {
    setAdminOpen(!adminOpen);
  };

  const handleUserManagementToggle = () => {
    setUserManagementOpen(!userManagementOpen);
  };

  const userRoles = account?.idTokenClaims?.roles || [];
  const isAdmin = userRoles.includes('Admin');
  const isUser = userRoles.includes('User');

  const renderMenuItems = (items) => {
    return items.map((item, index) => (
      <div key={index}>
        <ListItem 
          button 
          component={Link} 
          to={item.path} 
          onClick={item.children ? (item.text === 'Admin' ? handleAdminToggle : handleUserManagementToggle) : null}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
          {item.children && ((item.text === 'Admin' ? adminOpen : userManagementOpen) ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        {item.children && (
          <Collapse in={item.text === 'Admin' ? adminOpen : userManagementOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.children.map((child, idx) => (
                <ListItem button key={idx} component={Link} to={child.path} sx={{ pl: 4 }}>
                  <ListItemIcon>{child.icon}</ListItemIcon>
                  <ListItemText primary={child.text} />
                </ListItem>
              ))}
              {item.text === 'Employee Management' && users.map((user) => (
                <ListItem button key={user.id} component={Link} to={`/user/${user.id}`} sx={{ pl: 4 }}>
                  <ListItemIcon><Person /></ListItemIcon>
                  <ListItemText primary={user.username} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        )}
      </div>
    ));
  };

  return (
    <Drawer
      sx={{
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <List>
        {isAdmin && renderMenuItems(menuConfig.Admin)}
        {isUser && renderMenuItems(menuConfig.User)}
      </List>
    </Drawer>
  );
};

export default Sidebar;
