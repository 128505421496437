import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  Container,
  Box,
  AppBar,
  Toolbar,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/system';
import axiosInstance from './axiosConfig';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [newProject, setNewProject] = useState({
    company: '',
    name: '',
    manager_name: '',
    manager_email: '',
    one_drive_url: '',
    ticketing_system: '',
  });
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [filterCompany, setFilterCompany] = useState('');
  const [filterName, setFilterName] = useState('');
  const [editProject, setEditProject] = useState(null);
  const [deleteProject, setDeleteProject] = useState(null);

  useEffect(() => {
    loadProjects();
  }, []);

  const loadProjects = async () => {
    try {
      const response = await axiosInstance.get('/projects/');
      setProjects(response.data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProject((prevProject) => ({ ...prevProject, [name]: value }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditProject((prevEditProject) => ({ ...prevEditProject, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/projects/', newProject);
      setProjects((prevProjects) => [...prevProjects, response.data]);
      setNewProject({
        company: '',
        name: '',
        manager_name: '',
        manager_email: '',
        one_drive_url: '',
        ticketing_system: '',
      });
    } catch (error) {
      console.error('Error adding project:', error);
    }
  };

  const handleDelete = async (projectId) => {
    try {
      await axiosInstance.delete(`/projects/${projectId}`);
      setProjects(projects.filter((project) => project.id !== projectId));
      setDeleteProject(null); // Close the confirmation dialog
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const response = await axiosInstance.put(`/projects/${editProject.id}`, editProject);
      setProjects(projects.map((project) => (project.id === editProject.id ? response.data : project)));
      setEditProject(null);
    } catch (error) {
      console.error('Error updating project:', error);
    }
  };

  const handleEdit = (project) => {
    setEditProject(project);
  };

  const handleSortChange = (e) => {
    const { name, value } = e.target;
    setSortConfig({ ...sortConfig, [name]: value });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === 'filterCompany') {
      setFilterCompany(value);
    } else if (name === 'filterName') {
      setFilterName(value);
    }
  };

  const sortedAndFilteredProjects = projects
    .filter(
      (project) =>
        (filterCompany ? project.company.includes(filterCompany) : true) &&
        (filterName ? project.name.includes(filterName) : true)
    )
    .sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

  return (
    <Container sx={{ paddingTop: '20px', paddingLeft: 2, paddingRight: 2 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h5">Admin Panel</Typography>
        </Toolbar>
      </AppBar>
      <Box mt={4} mb={4} p={2} sx={{ backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
        <Typography variant="h6" sx={{ marginBottom: '20px' }}>
          Add New Project
        </Typography>
        <form onSubmit={handleSubmit} sx={{ marginBottom: '20px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Company"
                name="company"
                value={newProject.company}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                name="name"
                value={newProject.name}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Manager Name"
                name="manager_name"
                value={newProject.manager_name}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Manager Email"
                name="manager_email"
                value={newProject.manager_email}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="One Drive URL"
                name="one_drive_url"
                value={newProject.one_drive_url}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Ticketing System"
                name="ticketing_system"
                value={newProject.ticketing_system}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Add Project
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box>
        <Typography variant="h6" sx={{ marginBottom: '20px' }}>
          Projects List
        </Typography>
        <Grid container spacing={3} sx={{ marginBottom: '20px' }}>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel>Sort By</InputLabel>
              <Select name="key" value={sortConfig.key} onChange={handleSortChange}>
                <MenuItem value="company">Company</MenuItem>
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="manager_name">Manager Name</MenuItem>
                <MenuItem value="manager_email">Manager Email</MenuItem>
                <MenuItem value="one_drive_url">One Drive URL</MenuItem>
                <MenuItem value="ticketing_system">Ticketing System</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel>Sort Order</InputLabel>
              <Select name="direction" value={sortConfig.direction} onChange={handleSortChange}>
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Filter by Company"
              name="filterCompany"
              value={filterCompany}
              onChange={handleFilterChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Filter by Name"
              name="filterName"
              value={filterName}
              onChange={handleFilterChange}
              fullWidth
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{ maxHeight: '60vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableHeaderCell>Company</StyledTableHeaderCell>
                <StyledTableHeaderCell>Name</StyledTableHeaderCell>
                <StyledTableHeaderCell>Manager Name</StyledTableHeaderCell>
                <StyledTableHeaderCell>Manager Email</StyledTableHeaderCell>
                <StyledTableHeaderCell>One Drive URL</StyledTableHeaderCell>
                <StyledTableHeaderCell>Ticketing System</StyledTableHeaderCell>
                <StyledTableHeaderCell>Actions</StyledTableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedAndFilteredProjects.map((project) => (
                <TableRow key={project.id}>
                  <StyledTableCell>{project.company}</StyledTableCell>
                  <StyledTableCell>{project.name}</StyledTableCell>
                  <StyledTableCell>{project.manager_name}</StyledTableCell>
                  <StyledTableCell>{project.manager_email}</StyledTableCell>
                  <StyledTableCell>
                    <a href={project.one_drive_url} target="_blank" rel="noopener noreferrer">
                      One Drive Link
                    </a>
                  </StyledTableCell>
                  <StyledTableCell>
                    <a href={project.ticketing_system} target="_blank" rel="noopener noreferrer">
                      Ticketing System Link
                    </a>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setDeleteProject(project)}
                      style={{ marginRight: '10px' }}
                    >
                      DELETE
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => handleEdit(project)}>
                      EDIT
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {editProject && (
          <Dialog open={Boolean(editProject)} onClose={() => setEditProject(null)}>
            <DialogTitle>Edit Project</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Company"
                    name="company"
                    value={editProject.company}
                    onChange={handleEditChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Name"
                    name="name"
                    value={editProject.name}
                    onChange={handleEditChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Manager Name"
                    name="manager_name"
                    value={editProject.manager_name}
                    onChange={handleEditChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Manager Email"
                    name="manager_email"
                    value={editProject.manager_email}
                    onChange={handleEditChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="One Drive URL"
                    name="one_drive_url"
                    value={editProject.one_drive_url}
                    onChange={handleEditChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Ticketing System"
                    name="ticketing_system"
                    value={editProject.ticketing_system}
                    onChange={handleEditChange}
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setEditProject(null)} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleUpdateSubmit} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {deleteProject && (
          <Dialog open={Boolean(deleteProject)} onClose={() => setDeleteProject(null)}>
            <DialogTitle>Delete Confirmation</DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to delete the project <strong>{deleteProject.name}</strong>?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteProject(null)} color="secondary">
                Cancel
              </Button>
              <Button onClick={() => handleDelete(deleteProject.id)} color="primary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    </Container>
  );
};

export default Projects;
