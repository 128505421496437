import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  DialogContentText,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';
import axiosInstance from './axiosConfig';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: theme.typography.fontWeightBold,
  padding: '8px',
  textAlign: 'center',
  border: '1px solid #e0e0e0',
}));

const CertificateNameCell = styled(StyledTableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.background.paper,
}));

const AddCertificateButton = styled(Button)(({ theme }) => ({
  margin: '20px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const UserCertificates = ({ userId }) => {
  const [certificates, setCertificates] = useState([]);
  const [allCertificates, setAllCertificates] = useState([]);
  const [newCertificateId, setNewCertificateId] = useState('');
  const [newStatus, setNewStatus] = useState('In Progress');
  const [newExpirationDate, setNewExpirationDate] = useState(null); // State for expiration date
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [certificateToDelete, setCertificateToDelete] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (userId) {
      loadCertificates(userId);
      loadAllCertificates();
    }
  }, [userId]);

  const loadCertificates = async (userId) => {
    try {
      const response = await axiosInstance.get(`/users/${userId}/certificates`);
      setCertificates(response.data);
    } catch (error) {
      console.error('Error fetching certificates:', error);
      setError('Error fetching certificates');
    }
  };

  const loadAllCertificates = async () => {
    try {
      const response = await axiosInstance.get('/certificates/');
      const sortedCertificates = response.data.sort((a, b) => a.name.localeCompare(b.name));
      setAllCertificates(sortedCertificates);
    } catch (error) {
      console.error('Error fetching all certificates:', error);
      setError('Error fetching all certificates');
    }
  };

  const handleAddCertificate = async () => {
    if (newCertificateId) {
      const newCertificate = {
        user_id: parseInt(userId, 10),
        certificate_id: parseInt(newCertificateId, 10),
        status: newStatus,
        expiration_date: newExpirationDate ? newExpirationDate.toISOString().split('T')[0] : null,
      };

      try {
        const response = await axiosInstance.post('/user_certificates/', newCertificate);
        setCertificates((prev) => [...prev, response.data]);
        setIsDialogOpen(false);
        setNewCertificateId('');
        setNewStatus('In Progress');
        setNewExpirationDate(null); // Reset expiration date
      } catch (error) {
        console.error('Error adding certificate:', error);
        setError('Error adding certificate');
      }
    }
  };

  const handleDeleteCertificate = async () => {
    try {
      await axiosInstance.delete(`/user_certificates/${certificateToDelete}`);
      setCertificates(certificates.filter(cert => cert.id !== certificateToDelete));
      setIsDeleteDialogOpen(false);
      setCertificateToDelete(null);
    } catch (error) {
      console.error('Error deleting certificate:', error);
      setError('Error deleting certificate');
    }
  };

  const handleChangeStatus = async (certificateId, status) => {
    const updatedCertificate = {
      status,
    };

    try {
      const response = await axiosInstance.put(`/user_certificates/${certificateId}`, updatedCertificate);
      setCertificates(certificates.map(cert => cert.id === certificateId ? response.data : cert));
    } catch (error) {
      console.error('Error updating certificate:', error);
      setError('Error updating certificate');
    }
  };

  const handleChangeExpirationDate = async (certificateId, expirationDate) => {
    const updatedCertificate = {
      expiration_date: expirationDate ? expirationDate.toISOString().split('T')[0] : null,
    };

    try {
      const response = await axiosInstance.put(`/user_certificates/${certificateId}`, updatedCertificate);
      setCertificates(certificates.map(cert => cert.id === certificateId ? response.data : cert));
    } catch (error) {
      console.error('Error updating certificate expiration date:', error);
      setError('Error updating certificate expiration date');
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container sx={{ paddingTop: '20px' }}>
        <Box mt={4}>
          {error && (
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          )}
          <Typography variant="h5" sx={{ marginBottom: '20px' }}>
            Certificates
          </Typography>
          <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableHeaderCell>Certificate Name</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Status</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Expiration Date</StyledTableHeaderCell> {/* New column for expiration date */}
                  <StyledTableHeaderCell>Actions</StyledTableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {certificates.map((certificate) => (
                  <TableRow key={certificate.id}>
                    <CertificateNameCell>{certificate.certificate_name}</CertificateNameCell>
                    <StyledTableCell>
                      <Select
                        value={certificate.status}
                        onChange={(e) => handleChangeStatus(certificate.id, e.target.value)}
                      >
                        <MenuItem value="Passed">Passed</MenuItem>
                        <MenuItem value="In Progress">In Progress</MenuItem>
                      </Select>
                    </StyledTableCell>
                    <StyledTableCell>
                      <DatePicker
                        value={certificate.expiration_date ? new Date(certificate.expiration_date) : null}  // Display the current expiration date
                        onChange={(newDate) => handleChangeExpirationDate(certificate.id, newDate)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => {
                          setCertificateToDelete(certificate.id);
                          setIsDeleteDialogOpen(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <AddCertificateButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setIsDialogOpen(true)}
          >
            Add Certificate
          </AddCertificateButton>
          <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} maxWidth="sm" fullWidth>
            <DialogTitle>Select Certificate</DialogTitle>
            <DialogContent>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Certificate</InputLabel>
                <Select
                  value={newCertificateId}
                  onChange={(e) => setNewCertificateId(e.target.value)}
                >
                  {allCertificates.map((certificate) => (
                    <MenuItem key={certificate.id} value={certificate.id}>
                      {certificate.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                >
                  <MenuItem value="Passed">Passed</MenuItem>
                  <MenuItem value="In Progress">In Progress</MenuItem>
                </Select>
              </FormControl>
              <DatePicker
                label="Expiration Date"
                value={newExpirationDate}
                onChange={(newDate) => setNewExpirationDate(newDate)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsDialogOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleAddCertificate} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={isDeleteDialogOpen}
            onClose={() => setIsDeleteDialogOpen(false)}
          >
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this certificate?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteCertificate} color="secondary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </LocalizationProvider>
  );
};

export default UserCertificates;
