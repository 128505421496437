import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from './axiosConfig';

const UserFeedbacks = ({ userId }) => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [newFeedback, setNewFeedback] = useState({
    date: '',
    feedback_text: '',
  });
  const [editFeedback, setEditFeedback] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [feedbackToDelete, setFeedbackToDelete] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    loadFeedbacks();
  }, [userId]);

  const loadFeedbacks = async () => {
    try {
      const response = await axiosInstance.get(`/users/${userId}/feedbacks`);
      setFeedbacks(response.data);
    } catch (error) {
      console.error('Error fetching feedbacks:', error);
      setError('Error fetching feedbacks');
    }
  };

  const handleAddFeedback = async () => {
    const feedback = {
      ...newFeedback,
      user_id: parseInt(userId, 10),
      year: new Date().getFullYear(), // Automatically set the year
    };

    try {
      const response = await axiosInstance.post('/feedbacks/', feedback);
      setFeedbacks([...feedbacks, response.data]);
      setNewFeedback({
        date: '',
        feedback_text: '',
      });
      setIsDialogOpen(false);
    } catch (error) {
      console.error('Error adding feedback:', error);
      setError('Error adding feedback');
    }
  };

  const handleUpdateFeedback = async (id) => {
    try {
      const response = await axiosInstance.put(`/feedbacks/${id}`, editFeedback);
      setFeedbacks(feedbacks.map((feedback) => (feedback.id === id ? response.data : feedback)));
      setEditFeedback(null);
    } catch (error) {
      console.error('Error updating feedback:', error);
      setError('Error updating feedback');
    }
  };

  const handleDeleteFeedback = async () => {
    try {
      await axiosInstance.delete(`/feedbacks/${feedbackToDelete}`);
      setFeedbacks(feedbacks.filter((feedback) => feedback.id !== feedbackToDelete));
      setIsDeleteDialogOpen(false);
      setFeedbackToDelete(null);
    } catch (error) {
      console.error('Error deleting feedback:', error);
      setError('Error deleting feedback');
    }
  };

  return (
    <Box mt={4}>
      {error && (
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      )}
      <Typography variant="h5" sx={{ marginBottom: '20px' }}>
        Feedbacks
      </Typography>
      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Feedback Text</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedbacks.map((feedback) => (
              <TableRow key={feedback.id}>
                <TableCell>{feedback.date}</TableCell>
                <TableCell>{feedback.feedback_text}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => setEditFeedback(feedback)}
                  >
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      setFeedbackToDelete(feedback.id);
                      setIsDeleteDialogOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setIsDialogOpen(true)}
        sx={{ marginTop: '20px' }}
      >
        Add Feedback
      </Button>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Add Feedback</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              label="Date"
              type="date"
              value={newFeedback.date}
              onChange={(e) => setNewFeedback({ ...newFeedback, date: e.target.value })}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Feedback Text"
              value={newFeedback.feedback_text}
              onChange={(e) => setNewFeedback({ ...newFeedback, feedback_text: e.target.value })}
              fullWidth
              margin="normal"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddFeedback} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      {editFeedback && (
        <Dialog open={true} onClose={() => setEditFeedback(null)}>
          <DialogTitle>Edit Feedback</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                label="Date"
                type="date"
                value={editFeedback.date}
                onChange={(e) => setEditFeedback({ ...editFeedback, date: e.target.value })}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Feedback Text"
                value={editFeedback.feedback_text}
                onChange={(e) => setEditFeedback({ ...editFeedback, feedback_text: e.target.value })}
                fullWidth
                margin="normal"
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditFeedback(null)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleUpdateFeedback(editFeedback.id)} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this feedback?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteFeedback} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserFeedbacks;
