import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Link,
} from '@mui/material';
import axiosInstance from './axiosConfig';

const areaColors = {
  "Technical Skills": "#f0f8ff",
  "Success": "#f5f5dc",
  "Freedom": "#ffe4e1",
  "Openness": "#e6e6fa",
  "Humanity": "#fafad2",
  "Effective Communication": "#ffb6c1", // Added color for Effective Communication
  "Leadeship Skills": "#ffefd5"
};

const levelColors = {
  "1-Unsatisfactory": "#f4cccc",
  "2-Improvement needed": "#fce5cd",
  "3-Satisfied": "#fff2cc",
  "4-Very Satisfied": "#d9ead3",
  "5-Outstanding": "#d0e0e3"
};

const formatDescription = (description) => {
  if (!description) return description;
  return description.split('•').map((item, index) => (
    item.trim() ? <div key={index}>{`• ${item.trim()}`}</div> : null
  ));
};

const convertToLinks = (text) => {
  if (!text) return text;
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part, index) => {
    if (urlRegex.test(part)) {
      return (
        <Link key={index} href={part} target="_blank" rel="noopener noreferrer">
          Link to the training
        </Link>
      );
    }
    return part;
  });
};

const UserCompetences = ({ userId }) => {
  const [competences, setCompetences] = useState([]);

  useEffect(() => {
    if (userId) {
      loadCompetences(userId);
    }
  }, [userId]);

  const loadCompetences = async (userId) => {
    try {
      const response = await axiosInstance.get(`/competences/${userId}`);
      const filteredData = response.data.data.filter(
        competence => competence.Skills && competence.Skills !== "Core Competencies"
      );
      setCompetences(filteredData);
    } catch (error) {
      console.error('Error fetching competences:', error);
    }
  };

  return (
    <Box mt={4}>
      <Typography variant="h5" sx={{ marginBottom: '20px' }}>
        Competences
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Area</TableCell>
              <TableCell>Skills</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Current Level</TableCell>
              <TableCell>Action Plan</TableCell>
              <TableCell>Time Frame</TableCell>
              <TableCell>Expected Level</TableCell>
              <TableCell>Outcome</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {competences.length > 0 ? (
              competences.map((competence, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor: areaColors[competence.Area] || '#ffffff'
                  }}
                >
                  <TableCell>{competence.Area}</TableCell>
                  <TableCell>{competence.Skills}</TableCell>
                  <TableCell>{formatDescription(competence.Description)}</TableCell>
                  <TableCell style={{ backgroundColor: levelColors[competence["Current Level"]] || areaColors[competence.Area] || '#ffffff' }}>
                    {competence["Current Level"]}
                  </TableCell>
                  <TableCell>{convertToLinks(competence["Action plan"])}</TableCell>
                  <TableCell>{competence["Time frame"]}</TableCell>
                  <TableCell style={{ backgroundColor: levelColors[competence["Expected Level"]] || areaColors[competence.Area] || '#ffffff' }}>
                    {competence["Expected Level"]}
                  </TableCell>
                  <TableCell>{competence.Outcome}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No competences available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserCompetences;
